<template>
	<v-app >
		<loading
			:active.sync="isLoading"
			:can-cancel="false"
			:is-full-page="true"
			:width="80"
			:height="80"
			:opacity="0.7"
			color="cornflowerblue"
		></loading>

		<v-main>
			<router-view :key="$route.fullPath" @loadShowHide="triggerLoading" />
		</v-main>
		
	</v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	components: {
		loading: Loading,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	methods: {
		triggerLoading(b) {
			if (b == "true" || b == true) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		},
	},
};
</script>
